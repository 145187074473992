import React from 'react'
import {graphql, StaticQuery} from "gatsby";
import {ArticlePreview} from '@cg-squad/ui-components'
import CategoryTitle from "../helpers/categoryTitle";
import {isMobile} from "react-device-detect";
import cx from "classnames";
import {rightSide} from '../../utils/adUnits'
import {
  delayMultiplicationFactor,
  adDisplayDelay
} from '../../utils/articleUtil'
import { addTimeout } from '../../utils/timeManager';

class PopularPosts extends React.Component {

    constructor(props) {
        super(props);
        this.className = cx(
            "hidden w-[305px] lg:flex flex-col justify-between p-4 bg-gray-100",
            props.className
        )
    }

  componentDidMount () {
    addTimeout(() => {
      rightSide('ht_rightcol_desktop')
    }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
  }

  renderItems() {
        return <StaticQuery
            query={graphql`
            query PopularPostsQuery {
              video: allDatoCmsArticle(
                    filter: {category: {elemMatch: {originalId: {in: ["39161838"]}}}, website: {elemMatch: {name: {eq: "ht"}}}}
                    limit: 1    
                    skip: 30
                ) {
                    nodes {
                        ...ArticleFieldsPluginWithPortraitImage
                    }
                }
                nonVideo: allDatoCmsArticle(
                    filter: {category: {elemMatch: {originalId: {nin: ["39161838"]}}}, website: {elemMatch: {name: {eq: "ht"}}}}
                    limit: 3
                    skip: 30
                ) {
                    nodes {
                        ...ArticleFieldsPluginWithPortraitImage
                    }
                }
            }
        `}
            render={data =>
                data.nonVideo.nodes.concat(data.video.nodes).slice(0, 3)
                    .map((article, index) => {
                    return <ArticlePreview
                    key={`top-right-${index}`} data={article}
                    className="flex w-full mb-4"
                    imageClass="mb-0 mr-2 float-left clear-both w-[70px] h-[70px]"
                    gatsbyImageClass="w-[70px] h-[70px]"
                    headingClass="font-outfit font-normal leading-snug text-base mb-0 line-clamp-3"
                    noImage={true}
                    noFlag={true}
                    noBody={true}
                    noAuthor={true}
                    wrappedImg={false}
                    dateAsFlag={true}
                    showNumber={true}
                    number={index+1}
                    authorAndReadingTime={true}
                    noReadMore={true}
                    titleAuthorDisplayClass={"flex-col-reverse"}
                    publishedAtFormat={"mmmm dd, yyyy"}/>
                })
            }
        />
    }

    render() {
        return !isMobile && <div className={"popular-posts article-popular relative ml-3 min-w-[305px]"}>
            <div className={this.className} data-datocms-noindex>
                {/*<CategoryTitle title="Popular Posts"/>*/}
                {this.renderItems()}
            </div>
          <div className="ad-container min-w-[305px] my-2">
            <div className="advertisement-text">Advertisement</div>
            <div id="ht_rightcol_desktop"/>
          </div>
        </div>
    }
}

export default PopularPosts;
